<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="ID"
      label="ID"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      @search-change="handleSearch"
      :disabled="isDisabledV2"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} - {{ props.option.contact_name }} - {{ props.option.address }}</span
          >
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title"
            >{{ props.option.code }} - {{ props.option.contact_name }} - {{ props.option.address }}</span
          >
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import _ from "lodash";
const MasterRepository = RepositoryFactory.get("master");
export default {
  props: {
    isGetData: {
      type: Boolean,
      default: true,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      selected: null,
      isDisabledV2:false,
      filters: {
        search: "",
        customer_id: null,
      },
    };
  },
  mounted() {
    this.isDisabledV2 = this.isDisabled
    if (this.isGetData) {
      this.getData();
    }
  },
  methods: {
    setDisabled(isDisabled) {
      this.isDisabledV2 = isDisabled;
      // this.isDisabled = isDisabled;
    },
    setFilters(filters) {
      this.filters = { ...this.filters, ...filters };
    },
    handleSearch: _.debounce(function (query) {
      if (query.length >= 2) {
        this.filters.search = query;

        this.getData({
          params: this.filters,
        });
      }
    }, 1000),
    async setByID(id) {
      try {
        const resp = await MasterRepository.getCustomerAddressByID(id);
        if (resp.code == 200) {
          this.selected = resp.data;
        } else {
          this.$vs.notify({
            title: "Error",
            text: "Error while fetching customer bill to",
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching customer bill to",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
    async setSelectedData(data) {
      this.selected = data;
    },
    async setContactName(contact_name) {
      if (contact_name&&this.selected!=null) {
        this.selected.contact_name = contact_name;
      }
    },
    async setAddress(address) {
      if (address&&this.selected!=null) {
        this.selected.address = address;
      }
    },
    async toggleDisabled() {
      this.isDisabledV2= !this.isDisabledV2;
      // this.isDisabled = !this.isDisabled;
    },
    async resetForm() {
      this.selected = null
    },
    async getData() {
      this.$vs.loading();
      try {
        const resp = await MasterRepository.getCustomerBillTo(this.filters);
        if (resp.code == 200) {
          if (resp.data.records.length == 1) {
            this.selected = resp.data.records[0];
            this.options = resp.data.records;
          } else {
            this.options = resp.data.records;
          }

          this.toggleDisabled();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching customer address ship to",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
  },
  watch: {
    selected(v) {
      this.selected = v;
      this.$emit("data", v);
    },
  },
};
</script>
